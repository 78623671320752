<template>
  <div>
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'account/accountUserView', query: { tabAccountApplication: 'account-application-settings' }, params: { user: id }})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
    </div>
    <UserList
      show-select
      v-model="selected"
      :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'ФИО', value: 'fio', sortable: false },
          { text: 'Почта', value: 'email', sortable: false },
        ]"
      :items="list?.items"
      :count="list?.count"
      :page="page"
      :size="size"
      :loading="pending"
    />
    <VFooter v-if="selected.length" app>
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
      <div class="py-3 grow">
        <VBtn color="primary" class="mr-2" depressed @click="onSave">Добавить</VBtn>
        <VBtn color="secondary" class="mr-2" depressed @click="() => selected = []">Отмена</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>
import {debounce, values, map, first} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { APPLICATION_STATUSES as STATUSES, INSPECTOR_TYPES as TYPES } from '@/store/inspect/enums';
import UserList from '@/components/account/Account/components/UserList.vue';

export default {
  name: 'AccountUserBind',
  components: {
    UserList
  },
  props: {
    id: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      pending: 'account/pending',
      list: 'user/list',
    }),
    statuses() {
      return values(STATUSES);
    },
    types() {
      return values(TYPES);
    },
  },
  methods: {
    ...mapActions({
      fetchAccountUserList: 'user/fetchList',
      changeAuthor: 'account/changeAuthor'
    }),
    onUpdateState: debounce(function () {
      const { page, size, filter } = this;
      this.fetchAccountUserList({ page, size, filter });
    }, 500),
    async onSave() {
      const id = this.id;
      const authorId = first(map(this.selected, 'id'));
      await this.changeAuthor({ id, authorId })
      this.$router.push({ name: 'account/accountUserView', query: { tabAccountApplication: 'account-application-settings' }, params: { user: id }});
    }
  },
  watch: {
    page: function () {
      this.onUpdateState();
    },
    size: function () {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function () {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.progress {
  top: 0;
}
</style>
